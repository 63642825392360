import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  DataGrid,
  GridToolbar,
  getGridNumericOperators,
} from "@mui/x-data-grid";
import { TextField } from "@mui/material";

const DataTable = () => {
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.brianspace.net/api/data")
      .then((res) => {
        const dataWithId = res.data.map((item, index) => ({
          id: index,
          ...item,
        }));
        setTableData(dataWithId);
        setFilteredRows(dataWithId);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const filteredData = tableData.filter((row) =>
      row.symbol.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRows(filteredData);
  }, [searchText, tableData]);

  const columns = [
    { field: "day", headerName: "Day", width: 110 },
    { field: "symbol", headerName: "Symbol", width: 100 },
    { field: "option_type", headerName: "Option Type", width: 120 },
    { field: "str", headerName: "Strike Price", width: 120 },
    { field: "order_count", headerName: "Order Count", width: 120 },
    { field: "exp", headerName: "Expiration", width: 130 },
    { field: "size_total", headerName: "Total Size", width: 120 },
    { field: "oi", headerName: "Open Interest", width: 120 },
    { field: "assumed_oi", headerName: "Assumed OI", width: 120 },
    {
      field: "vol",
      headerName: "Volume",
      width: 100,
      filterOperators: getGridNumericOperators().filter(
        (operator) => operator.value === ">" || operator.value === "<"
      ),
    },
    { field: "prems_total", headerName: "Total Premiums", width: 140 },
    { field: "prem_status", headerName: "Premium Status", width: 130 },
    {
      field: "dte",
      headerName: "Days to Expiry",
      width: 120,
      headerTooltip: "Number of days until the option expires",
    },
  ];

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <TextField
        variant="outlined"
        label="Search Symbol"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        sx={{ mb: 2 }}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        components={{
          Toolbar: GridToolbar,
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </div>
  );
};

export default DataTable;
