// Dashboard.js
import React from "react";
import DataTable from "./DataTable";

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <DataTable />
    </div>
  );
}

export default Dashboard;
