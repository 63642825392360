// Navbar.js
import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Options Dashboard
        </Typography>
        <Button
          color="inherit"
          component={NavLink}
          to="/"
          sx={{
            "&.active": {
              fontWeight: "bold",
              textDecoration: "underline",
            },
          }}
        >
          Home
        </Button>
        <Button
          color="inherit"
          component={NavLink}
          to="/dashboard"
          sx={{
            "&.active": {
              fontWeight: "bold",
              textDecoration: "underline",
            },
          }}
        >
          Dashboard
        </Button>
        <Button
          color="inherit"
          component={NavLink}
          to="/about"
          sx={{
            "&.active": {
              fontWeight: "bold",
              textDecoration: "underline",
            },
          }}
        >
          About
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
