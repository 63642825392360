// Routes.js
import React from "react";
import { Routes, Route } from "react-router-dom"; // Import Routes and Route
import Home from "./Home";
import Dashboard from "./Dashboard";
import About from "./About";
import NotFound from "./NotFound";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
